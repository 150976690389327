/**
  * Usages :
   @include texte( rem-calc(12), #FF0000, 400, 0.05rem, rem-calc(12), $body-font-color, center );
   @include texte( rem-calc(12), #000000 );
   @include texte( rem-calc(12), $align: right, $color: green );
   @include texte( $align: right, $color: green, $size: 12 );
*/
@mixin texte(
  $size: null,
  $color: null,
  $weight: null,
  $spacing: null,
  $height: null,
  $family: null,
  $align: null,
  $transform: null
) {
  font-size: $size;
  color: $color;
  font-weight: $weight;
  letter-spacing: $spacing;
  line-height: $height;
  font-family: $family;
  text-align: $align;
  text-transform: $transform;
}

@mixin padd-top {
  padding-top: rem-calc(100);
  @include breakpoint(1200px down) {
    padding-top: rem-calc(50);
  }
}
@mixin padd-bot {
  padding-bottom: rem-calc(100);
  @include breakpoint(1200px down) {
    padding-bottom: rem-calc(50);
  }
}

@mixin titles-homepage {
  font-family: $body-font-family;
  font-size: rem-calc(30);
  letter-spacing: -0.025em;
  line-height: rem-calc(40);
  color: $black;
  font-weight: $global-weight-bold;
  text-align: center;
  margin-bottom: 0;
  span {
    color:$secondary-color;
  }
}

@mixin titles-homepage-extranet {
  font-family: $body-font-family;
  font-size: rem-calc(30);
  letter-spacing: -0.025em;
  line-height: rem-calc(35);
  color: $black !important;
  font-weight: $global-weight-bold;
  text-align: left;

}

@mixin after-links {
  position: relative;
  &:after {
    position: absolute;
    background: $header-menu-selector;
    width: 0;
    height: rem-calc(3);
    left: 0;
    right: 0;
    margin: auto;
    content: "";
    z-index: 1;
    bottom: rem-calc(15);
    transition: 300ms;
  }
}


@mixin p {
  font-family: $body-font-family;
  font-size: rem-calc(14);
  line-height: rem-calc(28);
  color: $textes;
  font-weight: $global-weight-light;
  letter-spacing: 0.075em;
}

@mixin titles-items {
    font-size: rem-calc(16);
    line-height: rem-calc(28);
    color: $black;
    font-weight: $global-weight-bold;
    letter-spacing: 0.01em;
    margin-bottom: rem-calc(15);
    transition:color 300ms;
    &:hover {
        color:$secondary-color;
    }
}
