.about {
  @include padd-top;
  @include padd-bot;
  @include breakpoint(1200px down) {
    padding-left: 0 !important;
    padding-right: 0 !important;
    width: 100% !important;
  }

  &__link {
    background: transparent;
    padding:0;
    transition:transform 300ms;
    &:hover {
      background: transparent;
    }
  }

  &__toggleimg {
    transition: rotate 300ms;
    &.open {
      rotate: -90deg;
    }
  }

  h2 {
    @include titles-homepage;
    text-align: left;
    margin-bottom: rem-calc(20);
    margin-top: 0;

    @include breakpoint(768px down) {
      margin-top: rem-calc(20);
    }
  }
}