

.header__menu {
    position: relative;
    z-index: 99;
    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;
    background-color: $header-menu-background;
    padding:rem-calc(10 60);
    &.is-stuck {
        position: fixed;
        z-index: 99999;
       // animation-name: stickySlideDown;
        transition: none;
        box-shadow: 0px -5px 10px black;
        width: 100%;
        @if($use-transparent-header) {
            background:$header-menu-background-fixed;
        } @else {
            background:$header-menu-background;
        }
        @include breakpoint(1200px down) {
            display: none;
        }
    }
    @include breakpoint(1200px down) {
        padding:rem-calc(10);
    }
    li {
        padding: rem-calc(10 20);
        transition: 300ms;
        @include breakpoint(1440px down) {
            padding: rem-calc(10);
        }
        @include after-links;
        &.select,
        &:hover {
            &:after {
                width: rem-calc(30);
            }
        }
        li {
            &.select:after,
            &:hover:after {
            display: none;
            }
        }
        &.index {
            border: solid 1px $header-index-circle-border;
            border-radius: rem-calc(30);
            width: rem-calc(50);
            height: rem-calc(50);
            margin: auto rem-calc(10) auto auto;
            transform: scale(0.8);
        }
        a {
            color: $header-menu-links-color;
            margin: auto;
            font-size: rem-calc(16);
            line-height: rem-calc(24);
            transition: color 300ms;
            padding: 0 !important;
            @include breakpoint(1440px down) {
                font-size: rem-calc(14);
            }
            &:hover,
            &:focus {
                color: $header-menu-links-hover !important;
            }
        }
    }


    
    
    .submenu {
        li {
            border: solid 1px $medium-gray;
            border-bottom: 0;
            &:last-child {
                border-bottom: solid 1px $medium-gray;
            }
            padding:0;
            a {
                color: $textes;
                text-transform: none;
                margin: 0;
                display: block;
                width: 100%;
                height: 100%; 
                padding: rem-calc(15) !important;
                font-size: rem-calc(14);
                &.aselect {
                    color:$secondary-color;
                }
            }
        }
    }
 
    .button {
        margin: auto;
        padding:$header-button-padd;
        font-size: rem-calc(14);

        @include breakpoint(1200px down) {
            font-size: rem-calc(12);
            margin-right: 0;
        }
    }

    .menu-container {
        display: flex;
        place-content: center;
        @include breakpoint(1200px down) {
            display:none;
        }
    }

    .mainmenu {
        position: relative;
        display: inline-flex;
        &:after {
            position: absolute;
            background:$header-menu-after;
            content: "";
            height: 1px;
            width: 100%;
            left: 0;
            right: 0;
            margin: auto;
            bottom: rem-calc(15);
            z-index: 1;
            opacity: 0.3;
        }
    }
    li.recherche:after,
    li.index:after {
        display: none !important;
    }

    
    @if($use-transparent-header) {
        svg, #logo img {
            filter: brightness(0) invert(1);
        }
    } @else {
        svg, #logo img {
            filter:none;
        }
    }
  

    .dropdown.menu > li.is-dropdown-submenu-parent > a::after {
        display: none;
    }
    .dropdown.menu > li.is-active > a {
        color: $header-menu-links-hover !important;
    }
}

