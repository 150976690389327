/* -----------------------------------------
   Configuration de foundation
   Se référer au fichier : vendor/zurb/foundation/scss/settings/_settings.scss
----------------------------------------- */


/* exemple :
$foundation-palette: (
  primary: #b32a85,
  secondary: #15c55e,
  success: #000000,
  warning: #ffc31f,
  alert: #4d0fc0,
);
$breakpoint-classes: (small medium large xlarge);
*/

// 0 - Configuration du theme
$breadcrumbs-item-separator-item: '>';
$primary-color: #93132a;
$secondary-color:#ae8a3f;
$textes: #696969;
$column-gutter: rem-calc(10);
$light-gray: #f7f7f7;
$medium-gray: #cacaca;
$border:$secondary-color;
$dark-gray: #858585;
$black: #000;
$white: #fff;
@import '_theme2';
// 1. Global
// ---------
$global-width: rem-calc(1200);
$global-lineheight: rem-calc(24);
$foundation-palette: ( primary: #93132a, secondary: #ae8a3f, success: #36c66c, warning: #ffae00, alert: #e81f00, );
// 2. Breakpoints
// --------------
$breakpoint-classes: (small medium large xlarge);
$breakpoints: ( small: 0, medium:768px, large:1200px, xlarge: 1445px, xxlarge: 1600px, );
$breakpoints-hidpi: (hidpi-1: 1, hidpi-1-5: 1.5, hidpi-2: 2, retina: 2, hidpi-3: 3);
$print-breakpoint: large;
$body-background: $white;
$body-font-color: $textes;
$body-font-family: "Poppins",Helvetica,Roboto,Arial,sans-serif;
$body-font-family-variant: "Poppins",Helvetica,Roboto,Arial,sans-serif;
$body-font-header: "Poppins",Helvetica,Roboto,Arial,sans-serif;
$anchor-color-hover:$secondary-color;
// 4. Base Typography
// ------------------
$header-font-family: $body-font-family;
$header-font-weight: $global-weight-normal;
$header-styles: ( small: ( 'h1': ('font-size': 35), 'h2': ('font-size': 25), 'h3': ('font-size': 20), 'h4': ('font-size': 18), 'h5': ('font-size': 16), 'h6': ('font-size': 14), ), large: ( 'h1': ('font-size': 48), 'h2': ('font-size': 35), 'h3': ('font-size': 24), 'h4': ('font-size': 20), 'h5': ('font-size': 18), 'h6': ('font-size': 16), ), );
$global-margin: 1rem;
$global-padding: 1rem;
$global-position: 1rem;
$global-radius: 30px;
$global-weight-extra-light: 200;
$global-weight-light: 300;
$global-weight-normal: 400;
$global-weight-medium: 500;
$global-weight-bold: 700;
$global-weight-extra-bold: 800;
$paragraph-lineheight: rem-calc(28);
$paragraph-margin-bottom:rem-calc(20);
$paragraph-text-rendering: optimizeLegibility;
$blockquote-color: $textes;
$blockquote-padding: rem-calc(9 20 0 19);
$blockquote-border: 1px solid $medium-gray;
// 10. Breadcrumbs
// ---------------
$breadcrumbs-margin: 0 0 $global-margin 0;
$breadcrumbs-item-font-size: rem-calc(11);
$breadcrumbs-item-color: $textes;
$breadcrumbs-item-color-current: $textes;
$breadcrumbs-item-color-disabled: $textes;
$breadcrumbs-item-margin: 0.75rem;
$breadcrumbs-item-uppercase: false;
$breadcrumbs-item-separator: true;
$breadcrumbs-item-separator-item: '>';
$breadcrumbs-item-separator-color: $textes;
// 11. Button
// ----------
$button-font-family: $body-font-family;
$button-font-weight:$global-weight-normal;
$button-padding:0.9em 1.56em;
$button-margin: 0 0 $global-margin 0;
$button-background: $primary-color;
$button-background-hover: darken($primary-color, 30%);
$button-border: 1px solid transparent;
$button-hollow-border-width:2px;
$button-color-alt:$textes;
$button-color:white;
$button-radius: $global-radius;
$button-sizes: ( tiny:0.65rem, small: 0.775rem, default: 0.875rem, large: 1.25rem, );
$button-palette: $foundation-palette;
$button-opacity-disabled: 0.25;
$button-background-hover-lightness: -20%;
$button-hollow-hover-lightness: -20%;
$button-transition: background-color 0.25s ease-out, color 0.25s ease-out;
$button-responsive-expanded: false;
// 13. Callout
//
$callout-sizes: ( small: 0.5rem, default: 0.8rem, large: 3rem, );
$callout-font-weight:$global-weight-light;
$callout-background:transparent;
// 50. Table
// ---------
$table-background: transparent;
$table-border: 0;
$table-color-scale: 15%;
$table-hover-scale: 2%;
$table-striped-background: #eee;
$table-head-background: #ccc;
$table-foot-background: #ccc;
$table-stack-breakpoint: small;
// 52. Thumbnail
// -------------
$thumbnail-border: none;
$thumbnail-shadow: 0;
$thumbnail-shadow-hover: 0;
$topbar-background: $white;
$topbar-submenu-background: $topbar-background;
$menu-item-background-active:transparent;
$dropdownmenu-arrows:false;
// 20. Forms
// ---------

$input-radius: 3px;
$fieldset-border: 1px solid $medium-gray;
$fieldset-padding: rem-calc(20);
$fieldset-margin: rem-calc(18 0);
$legend-padding: rem-calc(0 3);
$form-spacing: rem-calc(16);
$input-error-font-weight: normal;
$input-prefix-color: $textes;
$input-prefix-background: $light-gray;
$input-prefix-border: 1px solid $medium-gray;
$input-prefix-padding: 1rem;
$form-label-color: $textes;
$form-label-font-size: rem-calc(14);
$form-label-font-weight:$global-weight-light;
$form-label-line-height: 1.8;
$select-background: $white;
$select-triangle-color: $dark-gray;
$select-radius:$input-radius;
$input-color: $textes;
$input-placeholder-color: $textes;
$input-font-family: $body-font-family;
$input-font-size: rem-calc(14);
$input-font-weight:$global-weight-light;
$input-background-disabled: $light-gray;
$input-border: 1px solid $medium-gray;
$input-border-focus: 1px solid $medium-gray;
$input-shadow: 0;
$input-shadow-focus: 0;
$input-transition: 0;
$input-number-spinners: true;
$form-button-radius: 3px;
// 21. Label
// ---------
$label-background:$secondary-color;
$label-color:white;
$label-color-alt:black;
$label-palette: $foundation-palette;
$label-font-size:0.6rem;
$label-padding: 0.33333rem 0.5rem;
$label-radius: $global-radius;
$form-label-color: $textes;
// 7. Accordion
// ------------
$accordion-background: transparent;
$accordion-plusminus: true;
$accordion-plus-content: '>';
$accordion-minus-content: '>';
$accordion-title-font-size: rem-calc(24);
$accordion-item-color: null;
$accordion-item-background-hover:transparent;
$accordion-item-padding: rem-calc(0 30 10 0);
$accordion-content-background: null;
$accordion-content-border:0;
$accordion-content-color: null;
$accordion-content-padding: rem-calc(20 0);
$dropdown-radius:0;

// 46. Responsive Embed

$responsive-embed-ratios: (
  default: 16 by 9,
  vertical: 9 by 16,
  panorama: 256 by 81,
  square: 1 by 1,
);


/* -----------------------------------------
 Import de foundation - Modifier les includes si besoin
----------------------------------------- */

/*ne pas toucher */

// Global styles
@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;
@include foundation-xy-grid-classes;
@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
@include foundation-prototype-classes;
// Generic components
@include foundation-button;
//@include foundation-button-group;
@include foundation-close-button;
@include foundation-label;
//@include foundation-progress-bar;
//@include foundation-slider;
//@include foundation-switch;
@include foundation-table;
// Basic components
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-callout;
@include foundation-card;
@include foundation-dropdown;
@include foundation-pagination;
//@include foundation-tooltip;
// Containers
@include foundation-accordion;
@include foundation-media-object;
//@include foundation-orbit;
@include foundation-responsive-embed;
@include foundation-tabs;
@include foundation-thumbnail;
// Menu-based containers
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-accordion-menu;
@include foundation-drilldown-menu;
@include foundation-dropdown-menu;
// Layout components
@include foundation-off-canvas;
@include foundation-reveal;
@include foundation-sticky;
//@include foundation-title-bar;
@include foundation-top-bar;

/*rien ci-dessous // fin du fichier */
