.list-annuaire {

    i.avocat-image,
    img.avocat-image {
        margin: auto;
        display: flex;
        place-content: center;
        border-radius: rem-calc(300);
        object-fit: cover;
        height: 100%;
        width: 100%;
        @include breakpoint (small down) {
            aspect-ratio: 1/1;
        }
    }

    .fa-user:before {
        font-size: rem-calc(30);
        margin:auto;
    }

    .svg-annuaire {
        width: 80px;
        height: 80px;
        @include breakpoint (small down) {
            width: 100%;
            height: 100%;
        }
    }
    .js-annuaire-contents {
        border:solid 1px $medium-gray;
        padding:rem-calc(20);
        background:white;
        box-shadow: 4px 2px 66px -25px rgba(0, 0, 0, 0.13);
        -webkit-box-shadow: 4px 2px 66px -25px rgba(0, 0, 0, 0.13);
        -moz-box-shadow: 4px 2px 66px -25px rgba(0, 0, 0, 0.13);
        margin-bottom: rem-calc(30);
    }

    
}


.annuaire-header {
    margin:auto;
    @include breakpoint(768px down) {
        display:flex;
        gap:rem-calc(10);
        &__media, &__contact {
            width: 35% !important;
        }
        &__title {
            width: 55% !important;
        }
        > div {
            margin:auto !important;
        }
    }
    @include breakpoint(425px down) {
        &__media, &__contact, &__title {
            width: 100% !important;
        }
    }

    &__media {
        margin:auto;
        &.cell {
            width:auto;
        }
    }
    &__title {
        margin:auto; 
        .header-title {
            @extend h5;
            color:$textes;
            transition:color 300ms;
            font-weight: $global-weight-bold;
            &:hover {
                color:$secondary-color;;
            }
        }
    } 
    &__contact  {
        margin:auto;
        display: flex;
        flex-direction: column;
        @include breakpoint(small down) {
            word-break: break-all;
        }
        a {
            @include p;
        }
    } 
    &__specialisations {
        margin:auto !important;
        place-content: end;
        ul {
            margin:0;
        }
        li {
            list-style: none;
            padding-left:rem-calc(10);
            position: relative;
            margin-bottom: rem-calc(5);
            &:before {
                content: ">";
                position: absolute;
                left:rem-calc(-5);
                top:rem-calc(-3);
                transition: 300ms;
                font-size: 1.125rem;
                color: $secondary-color;
                font-weight: 400;
                font-family: "Poppins";
            }
        }
        h3 {
            @extend h6;
            margin:auto;
            color:$textes;
            transition:color 300ms;
            font-weight: $global-weight-medium;
            &:hover {
                color:$secondary-color;
            }
        }

        .annuaire-link {
            margin:auto 0;
            @include breakpoint(768px down) {
                margin:rem-calc(15) 0 auto;
            }
        }
        .see-more {
            display: flex;
            margin:auto;
            width:rem-calc(40);
            border-radius: rem-calc(30);
            height:rem-calc(40);
            border:solid 2px $secondary-color;
            transition:300ms;
            span {
                place-content: center;
                margin:auto;
                color:$secondary-color;
                transition:300ms;
                font-size: rem-calc(18);
            }
            &:hover {
                background:$secondary-color;
                border-color:$secondary-color;
                span {
                    color:$white;
                }
            }
        }
    }
    &__media {
        a {
            border: solid 2px $secondary-color;
            display: block;
            border-radius: 300px;
            width: rem-calc(125);
            height:rem-calc(125);
            display: flex;
            place-content: center;
        }
    }
}


.annuaire-footer {
    border-top:solid 1px $medium-gray;
    padding-top:rem-calc(20);
    @include breakpoint(768px down) {
        word-break: break-word;
    }
    @include breakpoint(425px down) {
        display: block;
    }
    &__title {
        @extend h5;
        color:$secondary-color;
    } 
}
