.homeblocks {
  background: $light-gray;
  @include padd-bot;
  @include padd-top;
  &__container {
    gap: rem-calc(35);
    grid-template-columns: repeat(auto-fill, minmax(rem-calc(365), 1fr));
    @include breakpoint(1440px down) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @include breakpoint(960px down) {
      gap: rem-calc(20);
    }
     @include breakpoint(768px down) {
      grid-template-columns: 1fr;
      max-width: rem-calc(425);
      margin:auto;
    }
    @include breakpoint(425px down) {
      padding: rem-calc(0 20);
    }

  }

  &__maintitle {
    margin-bottom: rem-calc(35);
    h2 {
      @include titles-homepage;
    }
    p {
      font-family: $body-font-family;
      font-size: rem-calc(14);
      line-height: rem-calc(24);
      color: $black;
      font-weight: $global-weight-light;
      text-align: center;
      letter-spacing: 0.05em;
    }
  }
}

.homeblock {
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-color: $primary-color;
    aspect-ratio: 1/1;
  &__container {
    padding: rem-calc(25);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    height: 100%;
    @include breakpoint(960px down) {
      padding: rem-calc(15);
    }
    &:after {
      transition:300ms;
      position: absolute;
      content: "";
      background: $secondary-color;
      left: 0;
      right: 0;
      top: 0;
      border: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      opacity: 0;
      mix-blend-mode: multiply;
    }
    &:hover {
      &:after {
        opacity: 0.9;
      }
    }
  }
  &__pics {
    position:relative;
    z-index: 2;
  }
  &__title {
      font-family: $body-font-family;
      font-size: rem-calc(18);
      line-height: rem-calc(24);
      font-weight: $global-weight-bold;
      text-align: left;
      min-height: 2em;
      max-width: 50%;
      mark {
        margin: 0 -0.4em;
        padding: 0.1em 0.4em;
        background: white;
        -webkit-box-decoration-break: clone;
        box-decoration-break: clone;
        color:$secondary-color;
      }
  }
  &__bottom {
    padding-top: rem-calc(20);
    position:relative;
    justify-content: space-between;
    z-index: 2;
  }
  &__arrow {
    @include breakpoint(960px down) {
      width: rem-calc(30);
    }
    @include breakpoint(768px down) {
      width: auto;
    }
  }
  &__pics {
    overflow: hidden;
    img {
      max-width: rem-calc(215);
      height: 100%;
      object-fit: cover;
      @include breakpoint(1200px down) {
        max-width: rem-calc(150);
      }
      @include breakpoint(960px down) {
        max-width: rem-calc(100);
      }
      @include breakpoint(768px down) {
        max-width: rem-calc(150);
      }
    }
  }
}
