#overlay-search {
  z-index: 9999;
  .overlay-form {
    display: flex;
    height: 100%;

    .form__container {
        display: grid;
        grid-template-columns:1fr;
        place-content: center;
        align-items: baseline;
        grid-gap: rem-calc(10);
        width: 100%;
        max-width: rem-calc(768);
        margin: auto;
    }
  }
  .button-xlarge,
  .input-xlarge {
    $button-sizes: large;
  }
  .button {
    @include button-style();
    @include button-fill-style($background:$secondary-color, $background-hover:darken($secondary-color,15%), $color:white);
  }
  .input-xlarge {
    height: rem-calc(80) + rem-calc(1);
    font-size: rem-calc(25);
    padding-left:rem-calc(20);
  }
}



.overlay-search-button {
  cursor: pointer;
  border: 0;
  padding: 0 rem-calc(40);
  margin: 0;
  &:focus {
    outline: none;
  }
}

.close-reveal-modal {
    svg {
        stroke:$secondary-color;
    }
}



.csstransitions {
    #overlay-search.open {
        left: 0;
    }
    #overlay-search {
        left: -100vh;
        transition: left .2s linear;
    }
}
