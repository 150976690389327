/*
 * Personnalisation
 */
/*
#tarteaucitronRoot #tarteaucitronAlertBig .tarteaucitronAllow {
    @include button-style($primary-color, auto, auto);
}

#tarteaucitronRoot #tarteaucitronAlertBig .tarteaucitronDeny {
    @include button-style($secondary-color, auto, auto);
}
*/




/*********************************************************

    TARTE AU CITRON 

*********************************************************/


span#tarteaucitronDisclaimerAlert {
  width: auto !important;
  display: block;
  text-align: center !important;
  margin: rem-calc(20) 0 !important;
  letter-spacing: 0.15em !important;
  font-family: $body-font-family  !important;
  font-weight: 400 !important;
}

#tarteaucitronRoot #tarteaucitronAlertBig button {
  padding: rem-calc(10 20) !important;
  background: transparent !important;
  border: solid 1px $white  !important;
  font-size: rem-calc(12) !important;
  letter-spacing: 0.085em;
  text-transform: uppercase;
  font-weight: 400 !important;
  font-family: $body-font-family  !important;
  margin: 0 rem-calc(5) !important;
  transition: 300ms;
  color: $white  !important;

  &:hover {
    background: $white  !important;
    color: $primary-color  !important;
  }

  @include breakpoint(768px down) {
    margin: rem-calc(5) 0 !important;
  }
}

#tarteaucitronRoot #tarteaucitronAlertBig #tarteaucitronPersonalize,
#tarteaucitronRoot #tarteaucitronAlertBig #tarteaucitronCloseAlert {
  font-size: rem-calc(12) !important;
  color: $white  !important;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  font-weight: 400;

  &:hover {
    background: $white  !important;
    color: $primary-color  !important;
  }
}

span#tarteaucitronDisclaimerAlert {
  font-size: rem-calc(12) !important;
  letter-spacing: 0.07em !important;
}

#tarteaucitronRoot #tarteaucitronAlertBig {
  width: 20% !important;
  background: $secondary-color  !important;
  padding: 30px !important;
  right: 0 !important;
  left: auto !important;

  @media screen and (max-width: rem-calc(1200)) {
    width: 30% !important;
  }

  @media screen and (max-width: rem-calc(768)) {
    width: 20% !important;
  }

  @media screen and (max-width: rem-calc(470)) {
    width: auto !important;
    padding: 10px !important;
  }
}

#tarteaucitronRoot #tarteaucitronAlertBig button {
  font-size: 10px !important;
  margin: 0px auto !important;
  display: block;
  text-align: center;
  padding: 6px !important;
  border: none !important;
  border-bottom: solid 1px white !important;
  padding-bottom: 5px !important;
  margin-bottom: 5px !important;
}

button#tarteaucitronCloseAlert,
#tarteaucitronRoot #tarteaucitronAlertBig #tarteaucitronCloseAlert {
  font-size: 10px !important;
  text-align: center;
}

#tarteaucitronRoot #tarteaucitronAlertBig button:hover,
#tarteaucitronRoot #tarteaucitronAlertBig #tarteaucitronCloseAlert:hover {
  background: transparent !important;
  color: darken($secondary-color, 60%) !important;
  border-color: darken($secondary-color, 60%) !important;
}

#tarteaucitronRoot #tarteaucitronAlertBig button:hover .tarteaucitronCheck::before,
button#tarteaucitronCloseAlert,
#tarteaucitronRoot #tarteaucitronAlertBig button:hover .tarteaucitronCross::before {
  color: darken($secondary-color, 60%) !important;
}


#tarteaucitronRoot a:focus-visible, 
#tarteaucitronRoot button:focus-visible {
    outline:none !important;
}