/* -----------------------------------------
   RefNat
----------------------------------------- */
.seo-header, .seo-header * {
	background: #000 !important;
	color: #FFF !important;
}

.seo, .seo * {
	text-align: center;
	border: 0 !important;
	font-size: 10px !important;
	background: none !important;
	font-weight: normal !important;
	color: #000 !important;
	padding: 0 !important;
	margin: 0 !important;
	display: inline !important;
	text-decoration: none !important;
	text-shadow: none !important;
}
.seo {
	display: block !important;
	width: 100%;
}
