.footer-menu {
    bottom: 0;
    width: 100%;
    .accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle)>a::after {
        right: .5rem;
    }
    .is-drilldown {
        min-height: unset !important;
        max-width: unset !important;
        overflow: inherit;
    }
    @include breakpoint(1201px up) {
        display: none;
    }
    &__close-button {
        top: 5%;
        left: 0;
        right: 0;
        margin: auto;
        background: transparent;
        width: 100%;
        &:hover {
            background: transparent;
        }
    }
    &__caller {
        background: $secondary-color;
        width: 100%;
        bottom: 0;
        z-index: 99999;
        padding: rem-calc(10);
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    // .footer-menu left & right
    &__container {
        place-content: center;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 30px;
    }
    &__container--left {
        grid-template-columns: 1fr 1fr;
    }
    // .footer-menu__middle
    &__middle {
        place-content: center;
        text-align: center;
    }
    // .footer-menu__content
    &__content {
        background: $secondary-color;
        width: 100%;
        position: fixed;
        top: rem-calc(-5000);
        padding-top: 26%;
        z-index: 9999999;
        height: 100%;
    }
    &__content.show {
        background: $secondary-color;
        width: 100%;
        position: fixed;
        top: 0;
        padding-top: rem-calc(125);
        z-index: 9999999;
        height: 100%;
    }
    &__open-menu {
        background: transparent;
        margin: 0;
        padding: 0;
        img {
            width: rem-calc(52);
            height: rem-calc(52);
            object-fit: content;
        }
        &:hover {
            background: transparent;
        }
    }
    .footer-icones {
        margin: auto;
        text-align: center;
        display: table;
    }
    .footer-content {
        margin: auto;
        text-align: center;
        svg {
            width: rem-calc(30);
            color: white;
        }
    }
    &__menu {
        height: calc( 100vh - 125px);
        overflow: scroll;
        a {
            padding: 0.8rem 0.5rem;
            width: 100%;
            background: transparent;
            text-transform: uppercase;
            font-size: rem-calc(18);
            line-height: rem-calc(24);
            color: $white;
            font-family: $body-font-family;
            letter-spacing: -0.025em;
            transition: 300ms;
            text-align: left;
            display: table;
            &:after {
                border-color: $white transparent transparent !important;
            }
        }
        // parents 
        .lateral__menu>li {
            border-bottom: 1px solid rgba(255, 255, 255, 0.2);
            &[aria-expanded="true"] {
                &>a {
                    color: $primary-color;
                }
            }
        }
        //sousmenu
        .submenu {
            background: transparent !important;
            li {
                margin: auto;
                border-bottom: solid 1px rgba(255, 255, 255, 0.2);
                a {
                    line-height: rem-calc(20);
                    font-weight: 300;
                    text-transform: none !important;
                    letter-spacing: -0.025em;
                    font-size: rem-calc(16);
                    padding-left: 1rem;
                }
                &.js-drilldown-back {
                    background: $white;
                    display: table;
                    margin: auto;
                    padding: rem-calc(0 10) !important;
                    a {
                        color: $secondary-color;
                    }
                }
            }
        }
    }
}