.connexion-form {
    legend {
        width: 100%;
        text-align: center;
        margin: 0 0 rem-calc(25) !important;
        color:$secondary-color;
        font-size: rem-calc(22);
        line-height: rem-calc(30);
    }
    button, .button {
        display:table;
        margin:auto;
    }
    form {
        border:solid 1px $medium-gray;
        padding:rem-calc(40);
        @include breakpoint(small down) {
            padding:rem-calc(15);
        }
    }
}

/* MDP OUBLIE */

form#oubli {
    
    margin: 0;
    padding: 0;
    
    div#rcode,
    div#roubli-submit {
        width: 100%;
    }
    .button {
        display:table;
        margin:auto;
    }

    legend {
        color:$textes;
        font-weight: $global-weight-medium;
        text-align: center;
        margin:rem-calc(20) auto;
        letter-spacing: 0.075em;
    }

    label {
        letter-spacing: 0.075em;
    }


    fieldset#identification {
        margin: 0;
    }

    div#roubli-submit {
        margin: rem-calc(20) 0;
    }

}



/******* INSCRIPTION ******/ 

button#inscription-submit {
    
  @extend .hollow, .small;
}

