$use-transparent-header: false;
$header-menu-background: white;
$header-index-circle-border: $secondary-color;
$header-menu-links-color: $black;
$header-menu-links-hover:$secondary-color;
$header-menu-selector:$secondary-color;
$header-menu-after: $light-gray;
$header-button-border-color:$secondary-color;
$header-button-color: $secondary-color;
$header-button-border-color-hover:darken($secondary-color, 20%);
$header-button-color-hover:darken($secondary-color, 20%);
$header-button-padd:rem-calc(10 15);
