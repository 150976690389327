.list-detail {
    &__themes {
        font-size: rem-calc(12);
        letter-spacing: 0.025em;
        margin:rem-calc(10 0);
    }
    &__titre {
        @extend h1;
        font-family: $body-font-family-variant;
        color: $secondary-color;
        font-weight: $global-weight-medium;
        letter-spacing: -0.01em;
    }
    &__menu {
        background:$secondary-color;
        li {
            border-bottom: solid 1px white;
            padding:rem-calc(10);
            a {
                line-height: rem-calc(30);
                font-size: rem-calc(16);
                color:white !important;
            }
        }
    }
}