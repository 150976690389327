/* Permet de faire un lien vers une ancre et de décaler le contenu qui est caché sous le header */
:target::before {
    content: '';
    display: block;
    height:      90px;
    margin-top: -90px;
}
.header {
  min-height: 75vh;
  display: flex;
  flex-direction: column;
  background-repeat: no-repeat;
  background-size: cover;
  &__container {
    margin: auto;
    position: relative;
    z-index: 9;

    @if($use-transparent-header) {
      padding: 0;
    }

    @else {
      padding: rem-calc(140 10);
    }

  }

  &__connexion--pub {
    margin: auto;
    display: flex;
    width: auto;

    @include breakpoint(1200px down) {
      margin-right: 0;
    }
  }


  &__connexion--link {
    color: $textes;
    text-align: center;
    font-size: rem-calc(12);
  }

  &__txt {
    .header__accroche {
      color: $white;
      font-family: $body-font-family;
      font-size: rem-calc(72);
      line-height: rem-calc(72);
      letter-spacing: -0.01em;
      font-weight: $global-weight-bold;
      margin-bottom: rem-calc(40);
      @include breakpoint(1200px down) {
        font-size: rem-calc(60);
        line-height: rem-calc(60);
      }

      @include breakpoint(768px down) {
        font-size: rem-calc(40);
        line-height: rem-calc(40);
      }
    }

    
  }

  &__scroll {
    width: rem-calc(60);
    height: rem-calc(60);
    border-radius: rem-calc(30);
    border: 1px solid white;
    display: flex;

    svg {
      margin: auto;
    }
  }

  &-intern {
    min-height: 50vh;
    @include breakpoint(small down) {
      min-height: rem-calc(350);
    }
    .header__accroche {
      font-size: rem-calc(50);
      line-height: rem-calc(60);

      @include breakpoint(1200px down) {
        font-size: rem-calc(40);
        line-height: rem-calc(50);
      }

      @include breakpoint(768px down) {
        font-size: rem-calc(30);
        line-height: rem-calc(40);
      }
    }

    .header__container {
      padding: 0;
    }
  }

  #example-dropdown {
    padding: rem-calc(35);

    .button {
      padding: rem-calc(10);
    }
  }

  #extranet-menu {
    @include breakpoint(960px down) {
      left: 0 !important;
      right: 0;
      width: 100%;
      margin: auto;
    }
  }


  #logo {
    min-height: rem-calc(70);
    display: flex;

    a {
      margin: auto;
    }

    @include breakpoint(1200px down) {
      max-width: rem-calc(110);
    }

    @include breakpoint(767px down) {
      max-width: rem-calc(110);
    }
  }
}

.sub-header {
    z-index: 9999;
  &__title {
    color: $white;
    font-family: $body-font-family;
    font-size: rem-calc(24);
    line-height: rem-calc(30);
    letter-spacing: -0.025em;
    font-weight: $global-weight-bold;
    text-align: center;
    margin: 0;
    span {
      color:$secondary-color;
    }
  }

  &__form {
    max-width: rem-calc(1000);
    margin: rem-calc(-50) auto rem-calc(-20);
    background: $primary-color;
    border-radius: rem-calc(100);
    @include breakpoint (large down) {
      max-width: 90%;
    }
    @include breakpoint (medium down) {
      border-radius: 0;
      margin:0 auto;
      max-width: 100%;
    }
  }

  &__submit {
    display: contents;
    cursor: pointer;
  }

  &__more {
    text-align: center;
    @include breakpoint (large) {
      text-align: right;
    }
    a {
      padding: rem-calc(12 20);
    }
  }

  .form-search {
    gap: rem-calc(20);

    @include breakpoint (large down){
      margin: rem-calc(20) auto rem-calc(20) auto;
    }
    @include breakpoint(768px down) {
      display: grid;
      gap: rem-calc(10);
    }


    svg {
      @include breakpoint(768px down) {
        margin: auto;
      }
    }
  }

  input,
  select {
    margin: auto !important;
    min-height: rem-calc(40);
    border-radius: rem-calc(5);

    @include breakpoint(768px down) {
      width: 100% !important;
    }
  }

  select option:first-child {
    font-size: rem-calc(15);
    color: $primary-color;
    font-family: $body-font-family;
  }

  select {
    background: url('./chevron.png') white no-repeat 95% center;
    cursor: pointer;
  }
}



.extranet-btn {
  margin: auto rem-calc(10);
  display: flex;
  place-content: center;

  @include breakpoint(767px down) {
    margin: auto 0;
    max-width: rem-calc(50) !important;
  }

  li {
    list-style: none;
    border-bottom: solid 1px $medium-gray;

    a {
      color: $primary-color;

      &:hover {
        color: $secondary-color;
      }
    }

    &:after {
      display: none !important;
    }
  }

}

.pub-connexion {
  margin-left: rem-calc(20);
  display: flex;
  color: $primary-color;
  i {
    margin: auto;
  }
}


.menu-container ul li ul {
  width: 0;
  height: 0;
  opacity: 0;
}

.no-js .menu-container {
  display: none;
}


.mainmenu {

  li.index {
    background: url('./home-picto.png') no-repeat center center;
  }

  li.index a {
    font-size: 0px;
    padding: 0 20px 20px !important;
    top: -8px;
    position: relative;
  }

}



/* debug anim menu */

.is-dropdown-submenu {
  background: white;
  border: 0 !important;

  li {
    background: white;
  }
}


.is-dropdown-submenu.js-dropdown-active {
  opacity: 1;
  animation-name: none;
}
