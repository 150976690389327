.margin-auto{
    margin: auto;
}

.d-grid{
    display: grid;
}

.mod-w1{
    width: 90vw;
}