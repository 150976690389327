html {
  scroll-behavior: smooth;
}

p {
  @include p;
}


.callout {
  @extend p;
}


form.valid input+small, 
form.valid img+small {
  @extend p;
}


.breadcrumbs {
  margin: rem-calc(20 0 50);

  li {
    @include p;
    text-transform: uppercase;
    font-size: rem-calc(11);

    a {
      color: $textes;
    }
  }
}

a {
  color: $secondary-color;
  transition: color 300ms;

  &:hover {
    color: darken($secondary-color, 15%);
  }
}


h1 {
  font-family: $body-font-family;
  color: $black;
  font-weight: $global-weight-bold;
  letter-spacing: -0.01em;
}




.bloc-chapo.rte p {
  font-size: rem-calc(22);
  line-height: rem-calc(34);
  margin-bottom: rem-calc(30);

  @include breakpoint(768px down) {
    font-size: rem-calc(18);
    line-height: rem-calc(30);
  }
}


.centre:not(.seccode) {
  margin: auto;
}

.plan {
  a {
    color: $secondary-color;

    &:hover {
      color: darken($secondary-color, 20%);
    }
  }
}

#rcontact-submit {
  width: 100%;

  button {
    display: table;
    margin: auto;
  }
}


hr {
  width: 100%;
}

form.themes {
  display: table;
  margin: 0;
  width: auto;
  text-align: center;
}




:focus {
  outline: 0;
}


ul.pagination {
  display: table;
  margin: auto;

  li {
    &:before {
      display: none;
    }
  }
}

p {
   @include p;
}

.pagination .current {
  background: transparent !important;
  color: $secondary-color  !important;
  padding: 0;
}


.pagination a,
.pagination button {
  color: darken($secondary-color, 20%);
}


ul.pagination li.current a,
ul.pagination li.current button {
  color: $secondary-color  !important;
  font-weight: $global-weight-light  !important;
}

.pagination a,
.pagination button {
  font-weight: $global-weight-light  !important;
}


