.accordion-item {
    &.is-active {
        .accordion-title:before {
            transform: rotate(-90deg);
        }
    }
    &+:not(.accordion-item) {
        margin-top: $global-margin;
    }
}

.accordion-title {
    border-bottom: solid 1px $medium-gray;
    margin: 0!important; // marge à zero pour eviter que l'accordeon sursaute
    padding: $accordion-content-padding;
    padding: $accordion-content-padding;
    padding-right: rem-calc(40);
    cursor: pointer;
    @include breakpoint (medium down) {
        font-size: rem-calc(18);
        line-height: rem-calc(24);
    }
    &:before {
        font-weight: 400;
        transform: rotate(90deg);
        transition: transform 300ms;
    }
}

.accordion-content {
    margin: 0!important; // marge à zero pour eviter que l'accordeon sursaute
    @extend p;
}