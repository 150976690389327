.list-annonces {
  &__item {
    box-shadow: 4px 2px 66px -25px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 4px 2px 66px -25px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 4px 2px 66px -25px rgba(0, 0, 0, 0.3);
    margin-bottom:rem-calc(40);
  }
  &__img {
    display: flex;
    height: auto !important;
    @include breakpoint(375px down) {
      min-height: rem-calc(150);
    }
    img {
      object-fit: cover;
    }
  }
  &__content {
    padding: rem-calc(25);
  }

  &__theme {
    @include p;
    font-size: rem-calc(12);
    line-height: rem-calc(20);
    text-transform: uppercase;
    display: inline-block;
    margin-bottom: rem-calc(10);
    @include breakpoint(1200px down) {
      display: none;
    }
  }

  &__title {
    font-size: rem-calc(16);
    line-height: rem-calc(26);
    color: $primary-color;
    font-weight: $global-weight-bold;
    letter-spacing: 0.01em;
    margin-bottom: rem-calc(15);
    @include breakpoint(1200px down) {
      font-size: rem-calc(14);
      line-height: rem-calc(22);
    }
  }

  &__footer {
    display: grid;
    grid-template-columns: 1fr 0.2fr;
    .button-group-icons {
        gap:rem-calc(10);
    }
  }

  &__link {
    border:solid 1px $border;
    transition: 300ms;
    display: flex;
    justify-content: center;
    padding: 8px;
    width: rem-calc(35) !important;
    height: rem-calc(35) !important;
    @include breakpoint(large down) {
      width: rem-calc(30) !important;
      height: rem-calc(30) !important;
    }
    &:hover {
      background: $secondary-color;
      border-color: $secondary-color;
      svg {
        stroke: white;
      }
    }
    svg {
      stroke: $secondary-color;
      transition: 300ms;
      height: 100%;
    }
  }
  &__date {
    @include p;
    font-size: rem-calc(12);
    margin:auto;
    line-height: rem-calc(20);
    @include breakpoint(large down) {
      display: none;
    }
  }
  &__excerpt {
    @include p;
    font-weight: $global-weight-extra-light;
    margin-bottom: rem-calc(15);
    letter-spacing: 0.075em;
    @include breakpoint(large down) {
      font-size: rem-calc(12);
      line-height: rem-calc(20);
    }
    @include breakpoint(small down) {
      display:none;
    }
  }

}